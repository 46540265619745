export function getItemsMax(width) {
  const breakpoints = [{
    min: 0,
    max: 679,
    nbItemsMax: 1.5
  }, {
    min: 680,
    max: 927,
    nbItemsMax: 2.5
  }, {
    min: 928,
    max: 1023,
    nbItemsMax: 3
  }, {
    min: 1024,
    max: 1279,
    nbItemsMax: 4
  }, {
    min: 1280,
    max: 1918,
    nbItemsMax: 5
  }, {
    min: 1919,
    max: Infinity,
    nbItemsMax: 6
  }];
  return breakpoints.find(breakpoint => width >= breakpoint.min && width <= breakpoint.max).nbItemsMax;
}
export function getFormData(form) {
  return Array.from(form.querySelectorAll('input')).reduce((object, element) => {
    object[element.name] = element.value;
    return object;
  }, {});
}